import Data from './data';
import * as _ from 'lodash';

// @ts-ignore TEMPORARY this will load all the chart data from the config folder
const roadmapData = ROADMAP_DATA;

export function loadDefaultRoadmapData(programId: number, defaultData = false): string | null {
    // Create empty chart data for now
    let data = defaultData ? `{"id": ${programId}}` : null;

    try {
        // Clone the deault data so it does not get updated
        // by any runtime code
        let clonedRoadmapData = _.cloneDeep(roadmapData);

        // This will grab the correct chart data for the program
        let programRoadmapData = _.find(clonedRoadmapData, (data) => {
            return parseInt(data.programId) === programId;
        });

        if (!!programRoadmapData) {
            // remove all existing items first
            Data.getInstance().removeRoadmap(programId);
            data = programRoadmapData;
        }
    } catch (error) {
        console.error(`Error loading roadmap data`, error);
    }

    return data;
}