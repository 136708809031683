import React, { Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import '../programPanels.css';
import './managementPanel.css';
import Breadcrumb from '../../breadcrumb/breadcrumb';
import Service from '../../../store/Service';
import { ProcessType } from '../../../interfaces/ProcessType';
import IRouteKey from '../../../interfaces/IRouteKey';
import RouteFactory from '../../../utils/RouteFactory';

class ManagementPanel extends React.Component <RouteComponentProps, any> {
	constructor (props) {
			super(props);
			let { dashboardId, programId, moduleId } = props.match.params;

			// TODO: FA consider having one call for all getManagementData(dashboard, progr, module)
			// => {dashboard: program, module}
			let programData = Service.getProgramData(dashboardId, programId);
			let moduleData = Service.getData(programData.modules, moduleId);
			let managementData = Service.getManagementData(dashboardId, programId, moduleId);

			switch(dashboardId) { 
				case 'standards':
					let data = managementData.data
					this.state = { 
						backgroundLink: data.backgroundLink,
						infoForWhatTile: data.infoForWhatTile,
						infoForWhyTile: data.infoForWhyTile,
						infoForHowTile: data.infoForHowTile,
						externalLinkPrimary: data.externalLinkPrimary, 
						externalLinkSecondary: data.externalLinkSecondary ? data.externalLinkSecondary : null,
						externalLinkModule: data.externalLinkModule,
						externalLinkName: data.externalLinkName,
						dashboardId: dashboardId,
						dashboardTitle: Service.appData.programsData[dashboardId].title,
						programId: programId,
						programTitle: programData.title,
						moduleId: moduleId, 
						moduleTitle: moduleData.title,
						forStandard: true
					}
					break;
				default:
					this.state = {
						dashboardId: dashboardId,
						dashboardTitle: Service.appData.programsData[dashboardId].title, // TODO: Change this to getDashboardData()
						programId: programId,
						programTitle: programData.title,
						moduleId: moduleId,
						moduleTitle: moduleData.title,
						managementData: managementData,
						externalProgram: moduleData.externalProgram,
						programDescription: programData.description,
						hasSystemView: (managementData !== undefined ? managementData.options.some((option) => option.systemOption) : false),
						hasSubArea: (managementData !== undefined ? managementData.options.some((option) => !option.primary) : false),
						imageSrc: (managementData && managementData.imageSrc) || '',
					}
			}
	}
	
	componentDidMount(){
		console.log("Entering Management Panel");
		if (this.state.externalProgram) {
			this.props.history.replace(`${this.state.routeKey.dashboardId}/programs/${this.state.externalProgram}/modules`);
			return null;
		}
	}

	componentWillUnmount(){
		console.log("Leaving Management Panel");
	}

	private routeToDetailPage = (routeKey: IRouteKey, type: ProcessType) => {
		let routes = RouteFactory.createRoutes(routeKey);
		if (routeKey.detailSpecificId) {
			this.props.history.push(`${routes.detailSpecificLink()}?type=${encodeURIComponent(type)}`)
		} else {
			this.props.history.push(`${routes.detailLink()}?type=${encodeURIComponent(type)}`)
		}
	}
	

	/** Generating RouteKeys */ 
	private createRenderRouteKey = () => { 
		let routeKey: IRouteKey = {
			dashboardId: this.state.dashboardId,
			dashboardTitle: this.state.dashboardTitle,
			programId: this.state.programId,
			programTitle: this.state.programTitle,
			moduleId: this.state.moduleId,
			moduleTitle: this.state.moduleTitle
		}
		
		return routeKey;
	}

	private createDetailRouteKey = (option) => {
		let routeKey: IRouteKey = {
			dashboardId: this.state.dashboardId,
			dashboardTitle: this.state.dashboardTitle,
			programId: this.state.programId,
			programTitle: this.state.programTitle,
			moduleId: this.state.moduleId,
			moduleTitle:this.state.moduleTitle,
			detailId: option.id,
			detailTitle: option.title
		}

		return routeKey;
	}

	/** Aids in rendering */
	private renderMgmtSubArea = () => {
			return <Fragment>
					<h3 className='sub-option-title'>Detailed Business & System Models</h3>
					<div className="subOptionContainer">
							{
									this.state.managementData && this.state.managementData.options.map((option, index) => {
											if (!option.primary && !option.detailList) {
													return (
														<div className="mgmt-item-subarea">
															<h5>{option.title}</h5>
															<div className="linkContainer">
																{option.bizOption && <p className="link" onClick={() => this.routeToDetailPage(this.createDetailRouteKey(option), ProcessType.BUSINESS)}>Business Process</p>}
																{option.bizOption && option.systemOption ? <p> | </p> : null}
																{option.systemOption && <p className="link" onClick={() => this.routeToDetailPage(this.createDetailRouteKey(option), ProcessType.SYSTEM)}>Sequence </p>}
															</div>
														</div>
													);
											} else if (option.detailList) { // For Process List with Categories
													let detailData = Service.getModuleData(this.state.dashboardId, this.state.programId, this.state.moduleId, option.id, ProcessType.DETAIL);
													let details = detailData.details // Getting all of the Details for Specific Option

													return ( 
																	<div className="mgmt-item-subarea">
																			<h5>{option.title}</h5> 
																					{
																							// New Route Key which changes the detailId on every iteration
																							details.map((detail) => {
																									let newRouteKey: IRouteKey = {
																											dashboardId: this.state.dashboardId,
																											dashboardTitle: this.state.dashboardTitle,
																											programId: this.state.programId,
																											programTitle: this.state.programTitle,
																											moduleId: this.state.moduleId,
																											moduleTitle:this.state.moduleTitle,
																											detailId: option.id,
																											detailSpecificId: detail.id // ID for Detail SVG
																									}
																									return (
																											<div>
																													{detail.icon && <img src={detail.icon} className='services-icon-detail'/> }
																													<p className="primary-link primary" onClick={() => this.routeToDetailPage(newRouteKey, ProcessType.DETAIL)}>{detail.title}</p>
																											</div>
																									);
																							})
																					}
																					
																			</div>
													);
											} else {
													return null;
											}
									})
							}
					</div>
			</Fragment>
	}

	private renderProcessLink = (type: ProcessType) => {
			let optionProperty = (type === ProcessType.BUSINESS) ? 'bizOption' : 'systemOption';
			
			return <Fragment>{
					// Figure out if these should be filtered beforehand.
					this.state.managementData && this.state.managementData.options.map((option, index) => {
							if (option.primary && option[optionProperty]) {
									switch(this.state.dashboardId){
											case 'services':
													return (
															<div className='services-link-area'>
																	{option.icon && <img src={option.icon} className='services-icon'/> }
															<p className='primary-link services-link'
																	key={option.id + ' ' + index}
																	onClick={() => this.routeToDetailPage(this.createDetailRouteKey(option), type)}>
																	{option.title}
															</p> 
															<p className='services-subtext'> 
																	{option.subtext}
															</p>
															</div>
															);
											case 'insights':
													return (
															<div>
																	{option.icon && <img src={option.icon} className='insights-icon'/> }
																	<p className='primary-link primary'
																			key={option.id + ' ' + index}
																			onClick={() => this.routeToDetailPage(this.createDetailRouteKey(option), type)}>
																			{option.title}
																	</p>
															</div>
															);
									}
							} else {
									return null;
							}
					})
			}</Fragment>
	}

	private renderImageFile = () => {
		return 	<Fragment>
					<div className='image-only-container'>
							<img className='image-only' src={this.state.imageSrc} />
					</div>
				</Fragment>
	}

	private renderMgmtArea = () => {
			// TODO: Change this strategy in next iteration
			if(this.state.dashboardId === "services"){
					return <Fragment>
							<div className='mgmtContainer services'> 
									<div className='services-area'>
											{this.renderProcessLink(ProcessType.BUSINESS)}
									</div>
							</div>
					{this.state.hasSubArea && this.renderMgmtSubArea()}
					{
							(!this.state.managementData || this.state.managementData.options.length == 0) &&
							<div className="no-data">
									<h2 className='warning'>No Management Areas Found</h2>
							</div>
					}
			</Fragment>
			} else {
					return <Fragment>
					<div className={this.state.hasSystemView ? 'mgmtContainer' : 'mgmtContainer systemViewOnly'}>
							<div className='biz-area'>
									<h3 className='button-main biz'>Business View</h3>
									{this.renderProcessLink(ProcessType.BUSINESS)}
							</div>
							{this.state.hasSystemView ? <div className='sys-area'>
									<h3 className='button-main sys'>System View</h3>
									{this.renderProcessLink(ProcessType.SYSTEM)}
							</div> : null}
					</div>
					{this.state.hasSubArea && this.renderMgmtSubArea()}
					{
							(!this.state.managementData || this.state.managementData.options.length == 0) &&  
							<div className="no-data">
									<h2 className='warning'>No Management Areas Found</h2>
							</div>
					}
			</Fragment>
			}
	}

	render() {
		// Industry and Practice Standards
		if (this.state.forStandard) {
			return (
				<div>
					<div className='row standardsBreadcrumb'>
						<Breadcrumb routeKey={this.createRenderRouteKey()}></Breadcrumb>
						<div className='panelHeader'>
							<h1>{this.state.moduleTitle}</h1>
						</div>
						<div className='panelDescription'>
							{this.state.programDescription}
						</div>
					</div>
					<div className='row'>
						<div className='standardsContainer'>
							<div className='left-side'>
								<img className='industryPracticeStandardsBackground' src={this.state.backgroundLink}></img>
								<div className='moduleBox'>
									<div className='moduleInformationTitle'>
										<h1 className='main-text'>What</h1>
										<span className='moduleInformation'>A definition identifying what it is</span>
									</div>
									<div className='moduleDetailedInformation'>
										{ this.state.infoForWhatTile }
									</div>
								</div>
								<div className='moduleBox middle'>
									<div className='moduleInformationTitle'>
										<h1 className='main-text'>Why</h1>
										<span className='moduleInformation'>An explanation of why we use it</span>
									</div>
									<div className='moduleDetailedInformation'>
										{ this.state.infoForWhyTile }
									</div>
								</div>
								<div className='moduleBox'>
									<div className='moduleInformationTitle'>
										<h1 className='main-text'>How</h1>
										<span className='moduleInformation'>An example of how/where we use it</span>
									</div>
									<div className='moduleDetailedInformation'>
										{ this.state.infoForHowTile }
									</div>
								</div>
							</div>
							<div className='right-side'>
								<div className='linksContainer'>
									<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-link-45deg" viewBox="0 0 16 16">
										<path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/>
										<path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/>
									</svg>
									<div className='KPMG-bold'>Links</div>
									<div className='primaryLinkResource'>For more information, please see: <br></br>
										<a href={this.state.externalLinkPrimary} target="_blank"><span className='lightblue'>{this.state.externalLinkModule}</span></a>
									</div>
									{ !!this.state.externalLinkSecondary ? (
										<div className='secondaryLinkResource'>Additional information may also be found in the Informational Bulletin issued on 
										<a href={this.state.externalLinkSecondary} target="_blank"><span className='lightblue'> {this.state.externalLinkName} </span></a>
																			in the Federal Policy Guidance section of Medicaid.gov.
										</div>
									) : ('') }
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
		else { 
			return (
				<div className='panelContainer'>
					<Breadcrumb routeKey={this.createRenderRouteKey()}></Breadcrumb>
					<div className='panelHeader'>
						<h1>{this.state.moduleTitle}</h1>
					</div>
					<div className='panelDescription'>
						{this.state.programDescription}
					</div>
					{this.state.imageSrc ? this.renderImageFile() : this.renderMgmtArea()}
				</div>
			);
		}
	}
}

export default withRouter(ManagementPanel);