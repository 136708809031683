import React, { Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import '../programPanels.css'; // TODO: There should only be one css for each panel
import './detailPanel.css';
import Breadcrumb from '../../breadcrumb/breadcrumb';
import { Button, ButtonGroup } from 'reactstrap';
import Service from '../../../store/Service';
import { ProcessType } from '../../../interfaces/ProcessType';
import IRouteKey from '../../../interfaces/IRouteKey';
import SchemaToggler from '../../schema_toggler/SchemaToggler';

class DetailPanel extends React.Component <RouteComponentProps, any> {

    constructor (props) {
        super (props);
        let {dashboardId, programId, moduleId, optionId, detailId} = props.match.params;
        let type:ProcessType = new URLSearchParams(props.location.search).get('type') as ProcessType;
        let programData = Service.getProgramData(dashboardId, programId);
        let moduleData = Service.getData(programData.modules, moduleId);
        let mgmtData = Service.getManagementData(dashboardId, programData.id, moduleId)
        let moduleDetailsData = Service.getModuleData(dashboardId, programId, moduleId, optionId, type);
        let detailInfo = Service.getData(mgmtData.options,optionId);
        let imagePath = '';
        let detailIndex = (detailId) ? detailId - 1 : 0
        if(moduleDetailsData){
            if(moduleDetailsData.details && moduleDetailsData.details.length > 0) {
                imagePath = moduleDetailsData.details[detailIndex].svg; 
            } else if(moduleDetailsData.options && moduleDetailsData.options.length > 0){ // This occurs in case there is a need for a Level Skip
                let option =moduleDetailsData.options.filter((option) => option.id === parseInt(optionId))
                imagePath = option[0].svg;
            } 
        } 

        this.state ={
            dashboardId: dashboardId,
            dashboardTitle: Service.appData.programsData[dashboardId].title, 
            programId: programId,
            programTitle: programData.title,
            programDescription: programData.description,
            moduleId: moduleId,
            moduleTitle: moduleData.title,
            detailId: detailInfo.id,
            detailTitle: (detailId && moduleDetailsData) ? moduleDetailsData.details[detailIndex].title : detailInfo.title, // Getting correct title depending if it is a detaillist or not
            moduleDetailsData: moduleDetailsData,
            hasModuleData: moduleDetailsData && moduleDetailsData.details && moduleDetailsData.details.length > 0,
            imagePath: imagePath
        }

     }

     private setImagePath =(svg: string) =>{
        this.setState({imagePath: svg});
     }

    private renderInteractiveButtons = () => {
        return (
            <ButtonGroup>
                {
                    this.state.moduleDetailsData.details.map(data => {
                        return <Button key={data.title}
                            color={`${this.state.imagePath === data.svg ? 'primary' : 'secondary'}`}
                            onClick={() => this.setImagePath(data.svg)}>{data.title}
                        </Button>
                    })
                }
            </ButtonGroup>
        );
    }

    private renderDetails = () => {

        if (this.state.imagePath.endsWith(".svg") && this.state.moduleDetailsData.details.length > 1) {
            return (
                <Fragment>
                    <div className='controlsContainer'>
                        <div className="controls center">
                            {this.renderInteractiveButtons()}
                        </div>
                    </div>
                    {
                        !!this.state.imagePath &&
                        <div className='detailContainer'>
                            <object type="image/svg+xml"
                                data={this.state.imagePath}>
                                {`Picture of the ${this.state.detailTitle} details.`}
                            </object>
                        </div>
                    }
                </Fragment>
            )
        } else if (this.state.imagePath.endsWith("schema")){
            return <SchemaToggler imageKey={this.state.imagePath}/>
        }else {
            return (
                <Fragment>
                    <div className='detailContainer'>
                        <img src={this.state.imagePath} alt="this is a temp for demoing only" />
                    </div>
                </Fragment>
            )
        }
    }

    render () {
        let routeKey:IRouteKey = {
            dashboardId: this.state.dashboardId,
            dashboardTitle:this.state.dashboardTitle,
            programId: this.state.programId,
            programTitle: this.state.programTitle,
            moduleId: this.state.moduleId,
            moduleTitle: this.state.moduleTitle,
            detailId: this.state.detailId,
            detailTitle: this.state.detailTitle
        }

        switch (this.state.dashboardId) {
            case 'services':
                return (
                    <div className='panelContainer'>
                        <Breadcrumb routeKey={routeKey} />
                        <div className='panelHeader'>
                            <h1>{this.state.detailTitle}</h1>
                        </div>
                        <div className='detailContainer'>
                            <img src={this.state.imagePath} alt="this is a temp for demoing only" />
                        </div>
                    </div>
                );
            default:
                return (
                    <div className='panelContainer'>
                        <Breadcrumb routeKey={routeKey} />
                        <div className='panelDescription'>
                            {this.state.programTitle}
                        </div>
                        {this.renderDetails()}
                        {
                            !this.state.hasModuleData &&
                            <div className="no-data">
                                <h2 className='warning'>No Details Found</h2>
                            </div>
                        }
                    </div>
                );
        }
    }
}

export default withRouter(DetailPanel);
