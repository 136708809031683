import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import IRouteKey from '../../../interfaces/IRouteKey';
import Service from '../../../store/Service';
import RouteFactory from '../../../utils/RouteFactory';
import Slider from 'react-slick';

import './dashboard.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { ReactComponent as InsightSVG } from './dashboard_svg/insights.svg';
import { ReactComponent as ServicesSVG } from './dashboard_svg/services.svg';
import { ReactComponent as StandardsSVG } from './dashboard_svg/standards.svg';
import { ReactComponent as ToolsSVG } from './dashboard_svg/tools.svg';

/**
 * @todo see if these can be added in pageConfig.js as part of the json files
 */
const QUADRANTS = {
    Insights: { id: 'insights', tooltip: 'Generic state-agnostic HHS-wide and program content including business operating models, systems, processes, and requirements' },
    Services: { id: 'services', tooltip: 'Aligned and rationalized federal program policies and guidelines, guided by industry approaches and standards' },
    Standards: { id: 'standards', tooltip: 'Aligned and rationalized federal program policies and guidelines, guided by industry approaches and standards' },
    Tools: { id: 'tools', tooltip: 'Content resides and is leveraged as data using an integrated set of tools to support the full methodology' }
}

const SETTINGS = {
    infinite: true,
    speed: 3650,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
};

interface Quadrant {
    id: string,
    tooltip: string
}

class Dashboard extends React.Component<RouteComponentProps, any> {

    constructor(props){
        super(props);
        this.state = {
            insightTooltipStyle: {display: 'none'},
            serviceTooltipStyle: {display: 'none'},
            standardTooltipStyle: {display: 'none'},
            toolsTooltipStyle: {display: 'none'}
        }
    }

    private routeToProgramPanel = (quadrant: Quadrant) => {
        let dashboardId = quadrant.id;
        let programData = Service.appData.programsData[dashboardId];

        let routeKey:IRouteKey = {
            dashboardId: dashboardId,
            dashboardTitle: programData.title
        }
        let routes = RouteFactory.createRoutes(routeKey);
        this.props.history.push(routes.dashboardLink());
    }

    private renderTooltip = (quadrant?: Quadrant) => {
        switch (quadrant) {
            case QUADRANTS.Insights:
                this.setState({
                    insightTooltipStyle: {display: 'block'}
                });
                break;
            case QUADRANTS.Services:
                this.setState({
                   serviceTooltipStyle: {display: 'block'}
                });
                break;
            case QUADRANTS.Standards:
                this.setState({
                    standardTooltipStyle: {display: 'block'}
                });
                break;
            case QUADRANTS.Tools:
                this.setState({
                    toolsTooltipStyle: {display: 'block'}
                });
                break;
            default:    
                this.setState({
                    insightTooltipStyle: {display: 'none'},
                    serviceTooltipStyle: {display: 'none'},
                    standardTooltipStyle: {display: 'none'},
                    toolsTooltipStyle: {display: 'none'}
                });
        }
    }

    render() {
        return (
            <div className="landing-page">
                <div className='row landing-page-row'>
                    <div className='col-md-4'>
                        <div className='container'>
                            <div className='row'>
                                <div className='logo-intro'> 
                                    <img className='landing-kera-logo' src='/icons/KERA_logo.png' alt='KERA Logo'></img>KERA
                                </div>
                                <div className='landing-page-greeting'>
                                    <span className="landing-blue-description">Welcome to KERA©</span> KPMG’s Enterprise Reference Architecture for Health & Human Services.
                                </div>
                                <div className='landing-page-description'>
                                    KERA combines methods, tools, and industry content to accelerate and reduce the risk of your transformation. 
                                    Please click on each section to learn more.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-8 full-length-col'>
                        <Slider {...SETTINGS}>
                            <img className='carousel' src='/icons/carousel/carousel1.jpg'></img>
                            <img className='carousel' src='/icons/carousel/carousel2.jpg'></img>
                            <img className='carousel' src='/icons/carousel/carousel3.jpg'></img>
                            <img className='carousel' src='/icons/carousel/carousel4.jpg'></img>
                        </Slider>
                        <div className='row quadrants-row stack'>
                            <div className='col-md-3 landing-page-insights'>
                                <div className='unhighlighted'>
                                    <img className='landing-page-icons' src='/icons/insights_icon.png'></img>
                                    <div className="landing-page-tile-description">Industry content and insights</div>
                                    <div onClick={()=>{this.routeToProgramPanel(QUADRANTS.Insights)}} className="explore-button quadrant">Explore</div>
                                </div>                            
                            </div>
                            <div className='col-md-3 landing-page-services'>
                                <div className='unhighlighted'>
                                    <img className='landing-page-icons' src='/icons/services_icon.png'></img>
                                    <div className="landing-page-tile-description">Services and methods</div>
                                    <div onClick={()=>{this.routeToProgramPanel(QUADRANTS.Services)}} className="explore-button quadrant">Explore</div>
                                </div>
                            </div>
                            <div className='col-md-3 landing-page-industry'>
                                <div className='unhighlighted'>
                                    <img className='landing-page-icons' src='/icons/industry_icon.png'></img>
                                    <div className="landing-page-tile-description">Industry and practice standards</div>
                                    <div onClick={()=>{this.routeToProgramPanel(QUADRANTS.Standards)}} className="explore-button quadrant">Explore</div>
                                </div>
                            </div>
                            <div className='col-md-3 landing-page-tools'>
                                <div className='unhighlighted'>
                                    <img className='landing-page-icons' src='/icons/tools_icon.png'></img>
                                    <div className="landing-page-tile-description smaller">Tools and assets</div>
                                    <div onClick={()=>{this.routeToProgramPanel(QUADRANTS.Tools)}} className="explore-button quadrant">Explore</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Dashboard);
