/* eslint-disable react/prop-types */
import React from 'react';
import { FormFeedback, Input } from 'reactstrap';
import { getIn } from 'formik';

//@ts-ignore
const FormInputField = ({ field, form: { touched, errors, setFieldValue }, ...props }) => {
    const isTouched = getIn(touched, field.name);
    const errorMsg = getIn(errors, field.name);
    const onChangeHandler = (event: any) => {
        let value = event.target.value;

        if (value === '') {
            value = null;
        }

        setFieldValue(field.name, value);
    };

    return (
        <div>
            <Input
                {...field}
                {...props}
                invalid={!!(isTouched && errorMsg)}
                onChange={onChangeHandler}
                value={field.value == null ? '' : field.value}
            />
            {isTouched && errorMsg && <FormFeedback>{errorMsg}</FormFeedback>}
        </div>
    );
};
export default FormInputField;
