import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import '../programPanels.css';
import './modulePanel.css';
import Breadcrumb from '../../breadcrumb/breadcrumb';
import * as _ from 'lodash';
import { Button } from 'reactstrap';
import Service from '../../../store/Service';
import IRouteKey from '../../../interfaces/IRouteKey';
import RouteFactory from '../../../utils/RouteFactory';

class ModulePanel extends React.Component<RouteComponentProps, any> {
    constructor(props) {
        super(props);

        let { dashboardId, programId } = props.match.params;
        let programData = Service.getProgramData(dashboardId, programId);

        let routeKey: IRouteKey = {
            dashboardId: dashboardId,
            dashboardTitle: Service.appData.programsData[dashboardId].title,
            programId: programId,
            programTitle: programData.title,
        }

        this.state = {
            routeKey: routeKey,
            dashboardId: dashboardId,
            dashboardTitle: Service.appData.programsData[dashboardId].title,
            programId: programId,
            programTitle: programData.title,
            modules: _.sortBy(programData.modules, ['sequence']),
            programDescription: programData.description && programData.modules.length > 0 ? programData.description : '',
            width: programData.width,
            programData: programData
        }
    }

    componentDidMount(){
        console.log("Entering Module Panel");
    }

    componentWillUnmount(){
        console.log("Leaving Module Panel");
    }

    private handleClick = (routeKey:IRouteKey, t: any) => {
        let routes = RouteFactory.createRoutes(routeKey);
        if(routeKey.externalProgramId) {
            this.props.history.push(routes.externalProgramLink());
        } 
        else {
            this.props.history.push(routes.moduleLink()); 
        }
    }

    private handleRoadmapClick = () => {
        this.props.history.push(`/programs/${this.state.programId}/modules/roadmap`);
    }

    render() {

        return (
            <div className='panelContainer'>
                <Breadcrumb routeKey={this.state.routeKey} />
                <div className='panelHeader'>
                    <h1>{this.state.programData.title}</h1>
                    <Button color="primary"
                        onClick={this.handleRoadmapClick}>View Roadmap</Button>
                </div>
                <div className='panelDescription'>
                    {this.state.programDescription}
                </div>
                <div className='moduleContainer'>
                    {
                        this.state.modules.map((module, index) => {
                            let routeKey: IRouteKey = {
                                dashboardId: this.state.dashboardId,
                                dashboardTitle: this.state.dashboardTitle,
                                programId: this.state.programId,
                                programTitle: this.state.programTitle,
                                moduleId: module.id,
                                moduleTitle: module.title,
                                externalProgramId: (module.externalProgram ? module.externalProgram : null),
                                externalProgramTitle: (module.externalProgram ? module.title : null),
                            }

                            // TODO (FA): Can this be turned into card ? => I believe so, every card has either a Title/Description, Main Icon, and Corner Icon
                            return (
                                <div key={module.id +'-'+ index}
                                    className={`${module.width ? "module full" : "module"}`}
                                    onClick={()=>{this.handleClick(routeKey, this)}}>
                                    {module.cornerImg ? <img className='cornerImg' src={module.cornerImg} alt= ''></img> : null}
                                    {module.icon ? <img className='moduleIcon' src={module.icon} alt={module.title}></img> : null}
                                    <div className='moduleDescription'>
                                        {module.title}
                                    </div>
                                </div>
                            );
                        }
                        )
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(ModulePanel);
