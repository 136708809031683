import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik } from 'formik';
import * as _ from 'lodash';
import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, ButtonGroup, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import * as Yup from 'yup';
import Data from '../../../utils/data';
import { loadDefaultRoadmapData } from '../../../utils/roadmapUtils';
import Utils from '../../../store/Service';
import FormInputField from '../../fields/form-input-field';
import FormSelectField from '../../fields/form-select-field';
import './roadmap.css';
import Roadmap2 from './roadmap2';

interface IRoadmapChartProps extends RouteComponentProps {
    programId: number;
}

interface IRoadmapChartState {
    currentType: string,
    isLoaded: boolean,
    isError: boolean,
    isModalOpen: boolean,
    currentProjectData?: any,
    editProjectState?: EditProjectState
}

interface EditProjectState {
    originalProjectData: any,
    editProjectData: any
}

class RoadmapChart extends React.Component<IRoadmapChartProps, IRoadmapChartState> {
    roadmap: Roadmap2;

    currentData: any;

    formSchema: any;

    constructor(props: IRoadmapChartProps) {
        super(props);

        this.toggleModal = this.toggleModal.bind(this);
        this.editProjectData = this.editProjectData.bind(this);
        this.updateProject = this.updateProject.bind(this);

        this.formSchema = Yup.object().shape({
            title: Yup.string()
                .nullable()
                .required('Title is required.'),
            moduleID: Yup.number()
                .nullable(),
            build: Yup.object()
                .required()
                .shape({
                    value: Yup.number()
                        .nullable()
                        .min(0, 'Value cannot be less than 0.')
                        .required('Value is required.'),
                    duration: Yup.number()
                        .nullable()
                        .min(1, 'Duration cannot be less than 1.')
                        .required('Duration is required.'),
                    valueShares: Yup.array()
                        .of(Yup.object().shape({
                            title: Yup.string(),
                            key: Yup.string(),
                            share: Yup.number()
                                .nullable()
                                .min(0, 'Value cannot be less than 0.')
                                .max(100, 'Value cannot be greater than 100.')
                                .required('Value is required.')
                        }))
                })
        });

        this.roadmap = new Roadmap2({
            editProjectDataCallback: this.editProjectData,
        });

        this.state = {
            currentType: 'total',
            isLoaded: false,
            isError: false,
            isModalOpen: false,
            currentProjectData: null
        }
    }

    componentDidMount() {
        this.loadData(true);
    }

    componentWillUnmount() {
        if (this.roadmap) {
            this.roadmap.destroyChart();
        }
    }

    toggleType(type) {
        this.setState({
            currentType: type
        });

        if (!!this.roadmap) {
            this.roadmap.updateChart(this.props.programId, this.currentData, type);
        }
    }

    onReset = () => {
        this.loadData()
    }

    onSave = () => {
        this.save(this.currentData);
    }

    save(data: any) {
        Data.getInstance().upsertRoadmap(data).then(() => {
            this.loadData();
        });
    }

    loadData(seedData = false) {
        Data.getInstance().findRoadmap(this.props.programId).then((roadmapData) => {
            if (!!roadmapData) {
                this.currentData = _.cloneDeep(roadmapData);

                this.setState({
                    isLoaded: true
                }, () => {
                    this.roadmap.initChart(this.props.programId, this.currentData, 'total');
                });
            } else {
                if (seedData) {
                    // seed the data into the data store
                    // this may be removed in the future
                    this.seedData();
                } else {
                    this.setState({
                        isLoaded: false,
                        isError: true
                    });
                }
            }
        }, () => {
            this.setState({
                isLoaded: false,
                isError: true
            });
        });
    }

    seedData() {
        let data: any;

        try {
            data = loadDefaultRoadmapData(this.props.programId);
        } catch(error) {
            // Should not be an error, but log it anyway
            console.log(error);
        } finally {
            if (!!data) {
                this.save(data);
            } else {
                this.setState({
                    isLoaded: false,
                    isError: true
                });
            }
        }
    }

    editProjectData(id: number) {
        // find the project data
        let currentProjectData = this.currentData.projects.find((project) => project.id === id);
        let clonedProjectData = _.cloneDeep(currentProjectData);

        if (!clonedProjectData.build.valueShares) {
            clonedProjectData.build.valueShares = [];
        }

        this.copyValueShares(this.currentData.valueShares, clonedProjectData.build.valueShares);
        this.formatValueShares(clonedProjectData.build.valueShares);

        if (!!currentProjectData) {
            let editProjectState = {
                originalProjectData: clonedProjectData,
                editProjectData: clonedProjectData
            } as EditProjectState;

            this.setState({
                editProjectState: editProjectState
            }, () => this.toggleModal());
        }
    }

    toggleModal() {
        this.setState({
            isModalOpen: !this.state.isModalOpen
        });
    }

    updateProject(values: any) {
        let project = this.currentData.projects.find((project) => project.id === values.id);
        this.normalizeValueShares(values.build.valueShares);
        this.normalizeValues(values);

        if (!!project) {
            _.assign(project, values);
        }

        if (!!this.roadmap) {
            this.roadmap.updateChart(this.props.programId, this.currentData);
        }

        this.toggleModal();
    }

    //<h1 className='warning'>No Data Found</h1>
    render() {
        if (this.state.isLoaded) {
            return (
                <div className='content-container'>
                    <div className='controlsContainer'>
                        <div className="controls left">
                            <Button tag={Link} to={`/programs/${this.props.programId}/modules/roadmap/edit`}>
                                <FontAwesomeIcon icon={faCog} size="lg" />
                            </Button>
                        </div>
                        <div className="controls center">
                            {this.renderValueShareButtons()}
                        </div>
                        <div className="controls right">
                            <Button color="primary" onClick={() => this.onSave()}>Save</Button>
                            <Button color="secondary" onClick={() => this.onReset()}>Reset</Button>
                        </div>
                    </div>

                    <svg id="meatballChart"></svg>

                    <div className="total-budget">Total Projects Budget: <span id="total-budget">$0</span></div>
                    <div className="chart-key">
                        <span className="line hard">&nbsp;</span> Hard dependency
                        <span className="line soft">&nbsp;</span> Soft dependency
                    </div>
                    {
                        this.state.isModalOpen && this.state.editProjectState &&
                        <Modal isOpen={this.state.isModalOpen} toggle={this.toggleModal} centered={true}>
                            <Formik
                                initialValues={this.state.editProjectState.editProjectData}
                                validationSchema={this.formSchema}
                                onSubmit={this.updateProject}
                            >
                                <Form noValidate className="edit-form">
                                    <ModalHeader toggle={this.toggleModal}>Edit {this.state.editProjectState.originalProjectData.title}</ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <Label for="title">Title*</Label>
                                            <Field id="title" name="title" required component={FormInputField} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="moduleId">Linked Module</Label>
                                            <Field id="moduleId" name="moduleId" options={this.getModuleOptions()} component={FormSelectField} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="build.value">Value ($K)*</Label>
                                            <Field id="build.value" name="build.value" type="number" required component={FormInputField} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="build.duration">Duration (Months)*</Label>
                                            <Field id="build.duration" name="build.duration" type="number" required component={FormInputField} />
                                        </FormGroup>
                                        {this.renderValueShareFields(this.state.editProjectState.editProjectData)}
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="primary" type="submit">OK</Button>{' '}
                                        <Button color="secondary" onClick={this.toggleModal}>Cancel</Button>
                                    </ModalFooter>
                                </Form>
                            </Formik>
                        </Modal>
                    }
                </div>
            );
        } else {
            if (this.state.isError) {
                return (
                    <div className="content-container content-container-msg">
                        <div className="alert alert-danger" role="alert">
                            No Data Found
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="content-container content-container-msg">
                        <Spinner style={{ width: '5rem', height: '5rem' }} color="primary" />
                    </div>
                );
            }
        }
    }

    private renderValueShareButtons() {
        let valueShares = this.currentData.valueShares;
        if (!!valueShares && valueShares.length > 0) {
            return (
                <ButtonGroup>
                    {
                        valueShares.map((valueShare) => {
                            return (
                                <Button key={valueShare.key}
                                        color={`${this.state.currentType === valueShare.key ? 'primary' : 'secondary'}`}
                                        onClick={() => this.toggleType(valueShare.key)}>{valueShare.title}</Button>
                            );
                        })
                    }
                </ButtonGroup>
            );
        } else {
            return null;
        }
    }

    private renderValueShareFields(editProjectData) {
        let valueShares = editProjectData.build.valueShares;
        if (!!valueShares && valueShares.length > 0) {
            return valueShares.map((valueShare, index) => {
                if (valueShare.key !== 'total') {
                    return (
                        <FormGroup key={valueShare.key}>
                            <Label for={`build.valueShares.${valueShare.key}`}>{`${valueShare.title} Share (%)`}</Label>
                            <Field id={`build.valueShares.${valueShare.key}`} name={`build.valueShares[${index}].share`} type="number" required component={FormInputField} />
                        </FormGroup>
                    );
                } else {
                    // Don't render the total field
                    return null;
                }
            });
        } else {
            return null;
        }
    }

    private copyValueShares(source, target) {
        source.forEach(sourceValueShare => {
            if (sourceValueShare.key !== 'total') {
                // look for the same in the target
                // and add if not found
                let targetValueShare =
                    _.find(target, value => value.key === sourceValueShare.key);

                if (!targetValueShare) {
                    // add it to the target
                    target.push(_.cloneDeep(sourceValueShare));
                }
            }
        });
    }

    private formatValueShares(valueShares) {
        valueShares.forEach(valueShare => valueShare.share = _.toString(_.toNumber(valueShare.share) * 100));
    }

    private normalizeValueShares(valueShares) {
        valueShares.forEach(valueShare => valueShare.share = _.toString(_.toNumber(valueShare.share) / 100));
    }

    /**
     * Normalize the number values from strings back to numbers
     * Assumption is that the values have already been validated
     * @param values 
     */
    private normalizeValues(values) {
        try {
            if (!_.isNil(values.build.value)) {
                values.build.value = parseInt(values.build.value);
            }

            if (!_.isNil(values.build.duration)) {
                values.build.duration = parseInt(values.build.duration);
            }

            if (!_.isNil(values.moduleId) && values.moduleId.trim().length > 0) {
                values.moduleId = parseInt(values.moduleId);
            } else {
                // set the moduleId explicity to null
                values.moduleId = null;
            }
        } catch (error) {
            console.log('could not normalize values', error);
        }
    }

    private getModuleOptions() {
        let options: any = [];
        // @ts-ignore
        let appData = APP_DATA;
        let programData = Utils.getData(appData.programsData.insights.programs, this.props.programId);

        if (!!programData && !!programData.modules) {
            let moduleIds = this.getActiveModuleDetailIds(this.props.programId);
            options = _.filter(programData.modules, moduleData => {
                return moduleIds.has(moduleData.id);
            });
        }

        return _.sortBy(options, ['title']);
    }

    private getActiveModuleDetailIds(programId: number) {
        let ids = new Set<number>();
        // @ts-ignore
        let moduleDetailsData = MODULE_DETAILS_DATA;

        if (!!moduleDetailsData && moduleDetailsData.length > 0) {
            moduleDetailsData.forEach(moduleDetail => {
                if (moduleDetail.programId === programId &&
                    moduleDetail.moduleId != null &&
                    !!moduleDetail.details &&
                    moduleDetail.details.length > 0) {
                        ids.add(moduleDetail.moduleId);
                    }
            });
        }

        return ids;
    }
}

export default withRouter(RoadmapChart);
