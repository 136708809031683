import * as _ from 'lodash';
import { ProcessType } from '../interfaces/ProcessType';

/**
 * Class for utility functions
 * NOTE: Would be promoted to Data Layer for DB calls.
 */
class Utils {
    // @ts-ignore
    static appData = APP_DATA;

    // @ts-ignore
    static mgmtData = MANAGEMENT_DATA;

    // @ts-ignore
    static servicesData = SERVICES_DATA;

    // @ts-ignore
    static standardsData = STANDARDS_DATA;

    // @ts-ignore
    static moduleDetailsData = MODULE_DETAILS_DATA;


    static getData = (data, id) => {
        return _.find(data, item => item.id === parseInt(id));
    }

    static formatId(id) {
        return _.isNil(id) ? -1 : parseInt(id);
    }

    static getProgramData(dashboardId: string, programId: string) {
        try {
            return Utils.getData(this.appData.programsData[dashboardId].programs, programId);
        } catch (error) {
            console.warn('Error getting program data', error);
        }
    }

    static getManagementData(dashboardId: string, programId: string, moduleId: string) {

        let matchProgramId = this.formatId(programId);
        let matchModuleId = this.formatId(moduleId);
        try {
            switch(dashboardId){
                case 'services':
                    return _.find(this.servicesData, (item) => {
                        return item.programId === matchProgramId && item.moduleId === matchModuleId;
                    });
                case 'insights':
                    return _.find(this.mgmtData, (item) => {
                        return item.programId === matchProgramId && item.moduleId === matchModuleId;
                    });
                case 'standards':
                    return _.find(this.standardsData, (item) => { 
                        return item.programId === matchProgramId && item.moduleId === matchModuleId;
                    })
                default:
                    console.error('Not valid dashboard Id');
            }
           
        } catch (error) {
            console.warn('Error getting program data', error);
        }
    }

    // I dont think i need this since my layers dont go to the last layer
    static getModuleData(dashboardId: string, programId: string, moduleId: string, optionId: string, type?: ProcessType){
        
        let matchProgramId = this.formatId(programId);
        let matchModuleId = this.formatId(moduleId);
        let matchOptionId = this.formatId(optionId);

        try {
            switch(dashboardId){
                case 'services':
                    return _.find(this.servicesData, (item) => {
                        return item.programId === matchProgramId && item.moduleId === matchModuleId;
                    });
                case 'insights':
                    return _.find(Utils.moduleDetailsData, (item) => {
                        return item.programId === matchProgramId
                            && item.moduleId === matchModuleId
                            && item.optionId === matchOptionId
                            && (type ? item.type === type : !type);
                    });
                default:
                    console.error('Not valid dashboard Id');
            }
            
        } catch (error) {
            console.warn('Error getting program data', error);
        }
    }

}

export default Utils;