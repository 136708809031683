/* eslint-disable react/prop-types */
import React from 'react';
import { FormFeedback, Input } from 'reactstrap';
import { getIn } from 'formik';

//@ts-ignore
const FormSelectField = ({ field, form: { touched, errors, setFieldValue }, ...props }) => {
    const isTouched = getIn(touched, field.name);
    const errorMsg = getIn(errors, field.name);
    const onChangeHandler = (event: any) => {
        let value = event.target.value;

        if (value === '') {
            value = null;
        }

        setFieldValue(field.name, value);
    };

    const getOptions = () => {
        if (!!props.options && props.options.length > 0) {
            let options = [<option key='none' value="">None</option>];

            options = options.concat(props.options.map((option) => {
                return <option key={option.id} value={option.id}>{option.title}</option>;
            }));

            return options;
        } else {
            return null;
        }
    }

    return (
        <div>
            <Input
                {...field}
                {...props}
                type="select"
                invalid={!!(isTouched && errorMsg)}
                onChange={onChangeHandler}
                value={field.value == null ? '' : field.value}
            >
                { getOptions() }
            </Input>
            {isTouched && errorMsg && <FormFeedback>{errorMsg}</FormFeedback>}
        </div>
    );
};
export default FormSelectField;
