import IRouteKey from "../interfaces/IRouteKey";

class RouteFactory {
    static createRoutes = (routeKey: IRouteKey) => {
        return {
            dashboardLink(): string {
                return `/${routeKey.dashboardId}`;
            },
            programLink(): string {
                let link = `/${routeKey.dashboardId}`;
                if (routeKey.programId) {
                    link += `/programs/${routeKey.programId}/modules`;
                } else {
                    console.warn('Provide a programId');
                }
                return link;
            },
            moduleLink(): string {
                let link = this.programLink();
                if (routeKey.moduleId) {
                    link += `/${routeKey.moduleId}/mgmt`;
                } else {
                    console.warn('Provide a moduleId');
                }
                return link;
            },
            detailLink(): string {
                let link = this.moduleLink();
                if (routeKey.detailId) {
                    link += `/${routeKey.detailId}`
                }
                return link;
            },
            detailSpecificLink(): string {
                let link = this.detailLink();
                if (routeKey.detailSpecificId) {
                    link += `/details/${routeKey.detailSpecificId}`
                }
                return link
            },
            externalProgramLink(): string {
                return `/${routeKey.dashboardId}/programs/${routeKey.externalProgramId}/modules`
            },
            roadmapLink(): string {
                return `/programs/${routeKey.programId}/modules/roadmap`;
            }, 
            getLinks(): {id: string, title: string, path: string}[]{
                return [
                    {id: routeKey.dashboardId, title: routeKey.dashboardTitle, path: this.dashboardLink()},
                    {id: routeKey.programId || '', title: routeKey.programTitle || '', path: this.programLink()},
                    {id: routeKey.externalProgramId || '', title: routeKey.externalProgramTitle || '', path: this.externalProgramLink()},
                    {id: routeKey.moduleId || '', title: routeKey.moduleTitle || '', path: this.moduleLink()},
                    {id: routeKey.detailId || '', title: routeKey.detailTitle || '', path: this.detailLink()}
                ]
            }
        }
    }
}

export default RouteFactory;
