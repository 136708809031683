import React from 'react';
import { Switch, Route, withRouter } from "react-router-dom";

import Header from './components/header/header';
import Footer from './components/footer/footer';
import ProgramPanel from './components/panels/programPanel/programPanel';
import ModulePanel from './components/panels/modulePanel/modulePanel';
import ManagementPanel from './components/panels/managementPanel/managementPanel';
import DetailPanel from './components/panels/detailPanel/detailPanel';
import RoadMapPanel from './components/panels/roadmapPanel/roadmapPanel';
import Dashboard from './components/panels/dashboard/dashboard';
import RoadMapDataPanel from './components/panels/admin/roadmapData'
import './kera.css';

class App extends React.Component<any, any>{

    render() {
        return (
            <div className="App">
                <Header />
                <div className='bodyContainer'>
                    <Switch>
                        <Route exact path="/" component={Dashboard} />
                        <Route exact path="/:dashboardId" component={ProgramPanel} />
                        <Route exact path="/:dashboardId/programs/:programId/modules" render={(props) => ( 
                            <ModulePanel key={String(props.match.params.programId)} { ...props} />
                        )}/>
                        <Route exact path="/:dashboardId/programs/:programId/modules/:moduleId/mgmt" component={ManagementPanel} />
                        <Route exact path="/:dashboardId/programs/:programId/modules/:moduleId/mgmt/:optionId" component={DetailPanel} />
                        <Route exact path="/:dashboardId/programs/:programId/modules/:moduleId/mgmt/:optionId/details/:detailId" component={DetailPanel} />
                        
                        {/* These two will be handled later on */}
                        <Route exact path="/programs/:programId/modules/roadmap" component={RoadMapPanel} />
                        <Route exact path="/programs/:programId/modules/roadmap/edit" component={RoadMapDataPanel} />

                        <Route>
                            <h1>Invalid Page</h1>
                        </Route>
                    </Switch>
                </div>
                <Footer />
            </div>
        );
    }
}

export default withRouter(App);