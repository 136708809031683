import { Field, Form, Formik } from 'formik';
import React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { Spinner, Button } from 'reactstrap';
import Data from '../../../utils/data';
import './admin.css';
import { loadDefaultRoadmapData } from '../../../utils/roadmapUtils';

interface IRoadMapDataPanelProps extends RouteComponentProps<{
    programId: string;
}> { }

interface IRoadMapDataPanelState {
    isLoaded: boolean;
    roadmapData: any;
}

class RoadMapDataPanel extends React.Component<IRoadMapDataPanelProps, IRoadMapDataPanelState> {

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            roadmapData: null
        };
    }

    componentDidMount() {
        // load the data
        let programId = parseInt(this.props.match.params.programId);
        this.loadData(programId);
    }

    update = (values) => {
        if (!!values && !!values.data) {
            this.setState({
                isLoaded: false,
                roadmapData: null
            }, () => {
                let data = JSON.parse(values.data);
                Data.getInstance().upsertRoadmap(data).then(() => {
                    let programId = parseInt(this.props.match.params.programId);
                    this.loadData(programId);
                });
            });
        }
    }

    loadData(programId: number) {
        Data.getInstance().findRoadmap(programId).then((data) => {
            let json = JSON.stringify(data, null, 2);
            this.setState({
                isLoaded: true,
                roadmapData: {
                    data: json
                }
            });
        });
    }

    reset = () => {
        // This will grab the correct chart data for the program
        let programId = parseInt(this.props.match.params.programId);
        let data = loadDefaultRoadmapData(programId, true);
        this.update({ data: JSON.stringify(data, null, 2) });
    }

    render() {
        return (
            <React.Fragment>
                {this.state.isLoaded && this.renderForm()}
                {!this.state.isLoaded && this.renderLoading()}
            </React.Fragment>
        );
    }

    renderForm() {
        return (
            <div className='admin-content-container'>
                <Formik
                    initialValues={this.state.roadmapData}
                    onSubmit={this.update}>
                    <Form noValidate>
                        <Field id="data" name="data" component='textarea' />
                        <div className="admin-controls">
                            <Button color="primary" type="submit">Save</Button>
                            <Button color="secondary" onClick={this.reset}>Load Defaults</Button>
                            <Button color="secondary" tag={Link} to={`/programs/${this.props.match.params.programId}/modules/roadmap`}>Back</Button>
                        </div>
                    </Form>
                </Formik>
            </div>
        );
    }

    renderLoading() {
        return (
            <div className="admin-content-container admin-content-container-msg">
                <Spinner style={{ width: '5rem', height: '5rem' }} color="primary" />
            </div>
        );
    }
}

export default withRouter(RoadMapDataPanel);
