import React from 'react';
import { ButtonGroup, Button } from 'reactstrap';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import './SchemaToggler.css'
import XMLViewer from 'react-xml-viewer';

interface Props {
    imageKey: string;
}

interface State { 
    imageKey: string, 
    showPdf: string, 
    showJson: string, 
    showXml: string, 
    showXsd: string,
    json: string, 
    xml: string,
    xsd: string,
}

/**
 * This component is used for canonical schema 
 * It is similar to a tab navigation where each button has its own div
 * Props accepts an imageKey that can be modified in detailsConfig
 * e.g. provider.schema we split this key and we pick up the first string 
 * Doing this the component is intelligent enought to know which path it needs to pick.
 */
class SchemaToggler extends React.Component<Props,State> {
    constructor(props) {
        super(props);
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        const imageId = props.imageKey.split('.')[0]
        const URL = `${window.location.origin}/staticImages/Medicaid/canonical_schema/` + imageId;
        console.log(URL);
        this.state = {
            imageKey: URL,
            showPdf: 'show',
            showJson: 'hidden',
            showXml: 'hidden',
            showXsd: 'hidden',
            json: '',
            xml: '',
            xsd: '',
        }
    }

    componentDidMount() {
        fetch(this.state.imageKey+'.json')
            .then(res => res.json())
            .then(data =>  {
                this.setState({json: JSON.stringify(data, undefined, 2)});
            });

        fetch(this.state.imageKey+'.xml', {headers:  {'Content-Type': 'application/xml; charset=utf-8'}})
            .then(res => res.text())
            .then(str => {
                this.setState({xml: str});
            });

        fetch(this.state.imageKey+'.xsd', {headers:  {'Content-Type': 'application/xml; charset=utf-8'}})
            .then(res => res.text())
            .then(str => {
                this.setState({xsd: str});
            });
            
    }

    toggleTabs = (tabID) => {
        switch (tabID) {
            case 'pdf':
                this.setState({
                    imageKey: this.state.imageKey,
                    showPdf: 'show',
                    showJson: 'hidden',
                    showXml: 'hidden',
                    showXsd: 'hidden',
                });
                break;
            case 'json':
                this.setState({
                    imageKey: this.state.imageKey,
                    showPdf: 'hidden',
                    showJson: 'show',
                    showXml: 'hidden',
                    showXsd: 'hidden',
                });
                break;
            case 'xml':
                this.setState({
                    imageKey: this.state.imageKey,
                    showPdf: 'hidden',
                    showJson: 'hidden',
                    showXml: 'show',
                    showXsd: 'hidden',
                });
                break;
            case 'xsd':
                this.setState({
                    imageKey: this.state.imageKey,
                    showPdf: 'hidden',
                    showJson: 'hidden',
                    showXml: 'hidden',
                    showXsd: 'show',
                });
                break;
        }
    };

    render() {
        return <>
            <ButtonGroup>
                <Button color={this.state.showPdf === 'show' ? 'primary' : 'secondary'} onClick={() => { this.toggleTabs('pdf') }}>PDF</Button>
                <Button color={this.state.showJson === 'show' ? 'primary' : 'secondary'} onClick={() => { this.toggleTabs('json') }}>JSON</Button>
                <Button color={this.state.showXml === 'show' ? 'primary' : 'secondary'} onClick={() => { this.toggleTabs('xml') }}>XML</Button>
                <Button color={this.state.showXsd === 'show' ? 'primary' : 'secondary'} onClick={() => { this.toggleTabs('xsd') }}>XSD</Button>
            </ButtonGroup>
            <div className='container'>
                <div className={this.state.showPdf}>
                    <Document file={this.state.imageKey+'.pdf'} onLoadError={console.error}>
                        <Page pageNumber={1} scale='10.0' />
                    </Document>
                </div>
                <div className={this.state.showJson}>
                    <pre>{this.state.json}</pre>
                </div>
                <div className={this.state.showXml}>
                    <XMLViewer xml={this.state.xml} />
                </div>
                <div className={this.state.showXsd}>
                    <XMLViewer xml={this.state.xsd} />
                </div>
            </div>
        </>
    }
}

export default SchemaToggler;