import React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import IRouteKey from '../../interfaces/IRouteKey';
import RouteFactory from '../../utils/RouteFactory';

interface IBreadcrumbData extends RouteComponentProps {
    routeKey: IRouteKey;
}

class Breadcrumb extends React.Component <IBreadcrumbData, any> {
    render () {
        let routes = RouteFactory.createRoutes(this.props.routeKey);
        this.state = { 
            breadcrumbs: routes.getLinks().filter(route => route.id !== '')
        };
        return (
            <div className='breadcrumbs'>
                {
                    this.state.breadcrumbs.map((data, index) => {
                        let isLastElement = index === this.state.breadcrumbs.length -1;
                        if(!isLastElement)
                        {
                            return(<React.Fragment key={index}>
                                <Link to={data.path}>{data.title}</Link><span>&gt;</span>
                                </React.Fragment>)
                        } else {
                            return (<React.Fragment key={index}>{data.title}</React.Fragment>)
                        }
                    })
                    
                }
            </div>
        );
    }  
}

export default withRouter(Breadcrumb);
