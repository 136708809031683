import React from 'react';
import './footer.css';

interface IFooter {
    description?: string;
}
export default function Footer(props) {
    let description = (!!props.description) ? props.description : `© ${new Date().getFullYear()} KPMG, LLP.`;
    return (
        <div className='footerContainer'>
            <div className='footerDescription'>
                {description}
            </div>
        </div>
    )
}