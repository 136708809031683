import MinimongoData from './minimongoData';

export interface IData {
    upsertRoadmap: (data: any) => Promise<any>

    findRoadmap: (programId: number) => Promise<any>

    removeRoadmap: (programId: number) => Promise<any>
}

export enum Collection {
    ROADMAP = 'roadmap'
}

export default class Data {
    private static instance: IData;

    /**
     * Singleton: cannot instantiate directly
     */
    private constructor() { }

    /**
     * Gets instance of the Data implementation
     */
    public static getInstance(): IData {
        if (!Data.instance) {
            Data.instance = new MinimongoData();
        }

        return Data.instance;
    }
}