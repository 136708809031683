import minimongo from 'minimongo';
import { IData, Collection } from './data';

export default class MinimongoData implements IData {

    db: any;

    /**
     * TODO
     */
    public constructor() {
        const LocalStorageDb = minimongo.LocalStorageDb;

        // Create local db (using local storage)
        this.db = new LocalStorageDb({
            namespace: 'kera-online'
        }, ()=>{});

        // Add a collection to the database
        Object.values(Collection).forEach(name => {
            this.db.addCollection(name);
        })
     }

    upsertRoadmap (data: any): Promise<any> {
        return new Promise<any>((success, error) => {
            this.db[Collection.ROADMAP].upsert(data, success, error);
        });
    }

    findRoadmap(programId: number): Promise<any> {
        return new Promise<any>((success, error) => {
            this.db[Collection.ROADMAP].findOne({id: programId}, {}, success, error);
        });
    }

    removeRoadmap(programId: number): Promise<any> {
        return new Promise<any>((success, error) => {
            this.db[Collection.ROADMAP].remove({ id: programId }, success, error);
        });
    }
}