import React from 'react';
import { withRouter } from 'react-router-dom';
import Utils from '../../store/Service';
import './header.css';

/**
 * Component for the header of the whole application
 * @param props {any}
 */
class Header extends React.Component<any, any> {

    render() {
        return (
            <div className='headerContainer'>
                <div className='brand' onClick={() => this.props.history.push('/')}>
                    <div className='logo'>
                        <img src={Utils.appData.companyLogo} alt='KERA Online logo'></img>
                    </div>
                    <div className='title'>
                        <h1>{Utils.appData.pageTitle}</h1>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Header);
