import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import '../programPanels.css';
import './roadmap.css';
import Breadcrumb from '../../breadcrumb/breadcrumb';
import RoadmapChart from '../../chart/testChart/roadmapChart';
import Utils from '../../../store/Service';
import { compileDocumentSelector } from 'minimongo/lib/selector';

interface IRoadMapPanel extends RouteComponentProps {
    programId: number;
}

interface IRoadMapPanelState {
    isLoaded: boolean,
    programData: any, // Make this specific.
    programId: string
}

class RoadMapPanel extends React.Component<IRoadMapPanel, IRoadMapPanelState> {

    // @ts-ignore
    appData = APP_DATA;

    constructor(props) {
        super(props);
        let { programId } = props.match.params;

        // TODO (FA): 'isLoaded' seems to be a bad practice because we set this to false then when data
        // returns we set it to true, what if there's an error that happened in between ?
        // we need to set different states when there's an error, when it's still loading, when data gets retrieved

        this.state = {
            isLoaded: false, 
            programData: null,
            programId: programId
        };
    }

    componentDidMount() {
        // load the data
        let programData = Utils.getData(this.appData.programsData.insights.programs, this.state.programId);

        this.setState({
            isLoaded: true,
            programData: programData
        })
    }

    render() {
        if (this.state.isLoaded) {
            let programData = this.state.programData;
            let programId = parseInt(this.state.programId);

            return (
                <div className='panelContainer'>
                    {/* <Breadcrumb breadcrumbdata={this.getBreadcrumbsData()}></Breadcrumb> */}
                    {
                        !!programData.description &&
                        <div className='panelDescription'>
                            { programData.description }
                        </div>
                    }
                    <div className='roadmapContainer'>
                        <RoadmapChart programId={ programId }></RoadmapChart>
                    </div>

                </div>
            );
        } else {
            return null;
        }
    }

    private getBreadcrumbsData() {
        return [
            {
                path: '/programs',
                title: 'Industry Content & Insights'
            }, {
                path: `/programs/${this.props.programId}/modules`,
                title: this.state.programData.title
            }, {
                path: `/programs/${this.props.programId}/modules/roadmap`,
                title: 'Roadmap'
            }
        ];
    }
}

export default withRouter(RoadMapPanel);
