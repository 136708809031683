import * as d3 from 'd3';
import * as _ from 'lodash';

export interface Roadmap2Props {
    editProjectDataCallback: Function;
}

export default class Roadmap2 {
    /**
     * This variable is used for calculating the percent value of the module cost.
     * The value will be used as percent
     */
    readonly modulePercent = 5;

    /** Font size for the labels  */
    readonly  fontSize = '0.75em';

    /** this will set the rounded corners for the reactangles. */
    readonly  roundedCorners = 20;

    /** This value is used for pushing the x label axis down by value pixel */
    readonly labelOffset = 10;

    /** drag validation buffer  */
    readonly dragValidationBuffer = 2;

    /** invalid project color */
    readonly invalidProjectColor = '#ff4747';

    /** invalid project text color */
    readonly invalidProjectTextColor = 'white';

    /** utility functions for dealing with data /** invalid project color */
    dataUtils: any;

    /** for the rectangles chart this will initialize the base height */
    //let baseHeight = (!!data.baseHeight) ? data.baseHeight : 10;
    baseHeight = 10;

    /** the current value type for total value calculations */
    valueShare = 'total';

    /** the chart DIV HTML element */
    chartDiv: any;

    /** the D3 chart */
    svg: any;

    /** current chart width */
    width = 0;

    /** current chart height */
    height = 0;
    
    /** current column width */
    colWidth = 0;
    
    /** current half column width */
    halfColWidth = 0;
    
    /** column slice width */
    colSliceWidth = 0;

    /** system integration meatball data */
    siMeatballs = new Array<any>();
    
    /** meatball data */
    meatballs = new Array<any>();

    /** the chart connectors */
    connectors = new Array<any>();

    /** current program id */
    programId = -1;

    /** the chart data */
    data: any;

    /** props */
    props: Roadmap2Props;

    /** selection timeout */
    selectionTimeout: any = null;

    constructor(props: Roadmap2Props) {
        this.props = props;

        // Some quick helper functions to simplify data retrieval in d3
        this.dataUtils = {
            getX: (d) => {
                return d.x;
            },
            getY: (d) => {
                return d.y;
            },
            getR: (d) => {
                return d.r;
            },
            getD: (d) => {
                return d.d;
            },
            getHeight: (d) => {
                return d.height;
            },
            getPhaseHeight: (d) => {
                return this.height * d.height;
            },
            getColor: (d) => {
                return d.color;
            },
            getTitle: (d) => {
                return d.title;
            },
            getCurrentValue: (d) => {
                if (d.valueSharesMap && d.valueSharesMap.has(this.valueShare)) {
                    return d.valueSharesMap.get(this.valueShare) * d.value;
                } else {
                    return d.value;
                }
            },
            getCurrentValueFormatted: (d) => {
                let self = this.dataUtils;
                return self.getFormattedAmount(self.getCurrentValue(d));
            },
            getFormattedAmount: (amount) => {
                if (amount === 0 ) {
                    return '$0';
                } else {
                    if (amount >= 1000) {
                        return '$' + (amount / 1000) + ' M';
                    } else {
                        return '$' + amount + ' K';
                    }
                }
            }
        }

        // Bind event listeners
        this.dragstarted = this.dragstarted.bind(this);
        this.dragged = this.dragged.bind(this);
        this.dragended = this.dragended.bind(this);
        this.connectorPath = this.connectorPath.bind(this);
        this.drawChart = this.drawChart.bind(this);
    }

    /** 
     * Used to wrap svg text to fit a target space (with optional money value). 
     */
    private wrap(text: d3.Selection<SVGTextElement,any,any,any>, 
        width: number, height: number, money: string, fontSize: string, d: any ) {

        if (_.isNil(text)) {
            return;
        }

        let addModuleLink = (tspan: d3.Selection<SVGTextElement, any, any, any>, d: any): any => {
            if (!!d.moduleId) {
                return tspan.append("a")
                    .attr("xlink:href", `#/programs/${d.programId}/modules/${d.moduleId}/details`);
            } else {
                return tspan;
            }
        }
        
        text.each(function () {
            //@ts-ignore
            let text = d3.select(this),
                words = text.text().split(/\s+/),
                word,
                line = [],
                lineNumber = 0,
                lineHeight = 1.1, // em
                x = parseInt(text.attr('x')),
                y = parseInt(text.attr('y')),
                dy = 1, // em
                tspan = text.text(null);
                tspan = addModuleLink(tspan, d);

                tspan = tspan.append('tspan')
                    .attr('x', x)
                    .attr('y', 0)
                    .attr('dy', dy + 'em')
                    .attr('font-size', fontSize);

            while (word = words.shift()) {
                //@ts-ignore
                line.push(word);
                tspan.text(line.join(' '));
                //@ts-ignore
                if (tspan.node().getComputedTextLength() > width && line.length > 1) {
                    line.pop();
                    tspan.text(line.join(' '));
                    //@ts-ignore
                    line = [word];
                    tspan = text;
                    tspan = addModuleLink(tspan, d);

                    tspan = tspan.append('tspan')
                        .attr('x', x)
                        .attr('y', 0)
                        .attr('dy', dy + (++lineNumber * lineHeight) + 'em')
                        .attr('font-size', fontSize)
                        .text(word);
                }
            }

            // NOTE: This makes sense for our use case, but is odd for the reusability of this function
            if (money) {
                tspan = addModuleLink(text, d);
                tspan = tspan.append('tspan')
                    .attr('x', x)
                    .attr('y', 0)
                    .attr('font-size', fontSize)
                    .attr('dy', dy + (++lineNumber * lineHeight) + 'em')
                    .text(money);
            }

            // position vertically
            let textNode = text.node();
            let finalHeight = !!textNode ? textNode.getBBox().height : 0;
            y = (height - finalHeight) / 2;
            text.attr('y', y);
            text.selectAll('tspan').attr('y', y);
        });
    }

    /**
     * Calculate the total budget and update the text
     */
    private updateTotalBudget() {
        let total = 0;
        let totalMeatballs = [...this.meatballs, ...this.siMeatballs];
        
        totalMeatballs.forEach((project) => {
            total += this.dataUtils.getCurrentValue(project);
        });
        
        //@ts-ignore
        document.getElementById('total-budget').innerText = this.dataUtils.getFormattedAmount(total);
    }

    /**
     * Calculate the year totals
     */
    private calculateYearTotals() {
        // Clear existing values
        this.data.years.forEach(function (year, i) {
            year.value = 0;
            // siMeatballs[i].value = 0;
        });

        let totalMeatballs = [...this.meatballs, ...this.siMeatballs];

        totalMeatballs.forEach((project) => {
            let startYear = project.year;
            let endYear = project.year + Math.ceil((project.month + project.duration) / 12) - 1;
            let currentValue = this.dataUtils.getCurrentValue(project);

            if (startYear === endYear) {
                this.data.years[project.year].value += currentValue;
                return;
            }

            // Split project value between years
            let monthlyValue = currentValue / project.duration;
            let year = startYear;
            let monthsRemaining = project.duration;
            let value = 0;
            while (monthsRemaining > 0 && this.data.years[year]) {
                if (year === startYear) {
                    let months = 12 - project.month;
                    value = monthlyValue * months;
                    monthsRemaining -= months;
                } else {
                    value = monthlyValue * Math.min(monthsRemaining, 12);
                    monthsRemaining -= 12;
                }
                this.data.years[year].value = Math.round((this.data.years[year].value + value) / 10) * 10;
                year++;
            }
        });
    }

    /**
     * TODO
     */
    private updateYearTotals() {
        this.calculateYearTotals();
        
        this.data.years.forEach((year, i) => {
            //@ts-ignore
            document.getElementById('year-value-' + i).textContent = this.dataUtils.getFormattedAmount(year.value);
        });
    }

    /** 
     * Generates the connector arrow marker
     */
    private addMarker() {
        this.svg.append('defs').append('marker')
            .attr('id', 'connector-arrow')
            .attr('viewBox', '0 0 5 10')
            .attr('refX', 5)
            .attr('refY', 5)
            .attr('markerWidth', 6)
            .attr('markerHeight', 6)
            .attr('orient', 'auto')
            .append('path')
            .attr('d', 'M 0 0 L 5 5 L 0 10 z')
    }

    /**
     * TODO
     */
    private findMeatballById(id) {
        let combinedMeatballs = [...this.meatballs, ...this.siMeatballs];
        
        return _.find(combinedMeatballs, data => {
            return data.id === id;
        });
    }

    /** 
     * Generates the path data for the project connection lines
     */ 
    private connectorPath(connectorData) {
        let start = this.findMeatballById(connectorData.from);
        let end = this.findMeatballById(connectorData.to);

        if (!start || !end) { return ''; }

        let startCX = start.x + start.r,
            startCY = start.y + start.height,
            startL = start.x,
            startR = start.x + start.d,
            startT = start.y,
            startB = start.y + start.height,
            endCX = end.x + end.r,
            endCY = end.y + end.height,
            endL = end.x,
            endR = end.x + end.d,
            endT = end.y,
            endB = end.y + end.height,
            xDist = Math.abs(endCX - startCX),
            yDist = Math.abs(endCY - startCY),
            cpDist = 30; // Control point distance

        let path = 'M ';

        // Path start point
        let startPoint = connectorData.start;
        
        if (!startPoint) {
            // TODO: Logic to determine start point
            startPoint = 'right';
        }
        switch (startPoint) {
            case 'top':
                path += startCX + ',' + startT + ' C ' + startCX + ',' + (startT - cpDist);
                break;
            case 'bottom':
                path += startCX + ',' + startB + ' C ' + startCX + ',' + (startB + cpDist);
                break;
            case 'left':
                path += startL + ',' + (startT + start.height / 2) + ' C ' + (startL - cpDist) + ',' + (startT + start.height / 2);
                break;
            case 'right':
            default: // TODO: Make default calculated
                path += startR + ',' + (startT + start.height / 2)
                    + ' C ' + (startR + cpDist) + ',' + (startT + start.height / 2);
                break;
        }

        // Path end point
        let endPoint = connectorData.end;
        if (!endPoint) {
            if (endB < startCY) {
                endPoint = 'bottom';
            } else if (endT > startCY) {
                endPoint = 'top';
            } else {
                endPoint = 'left';
            }
        }

        switch (endPoint) {
            case 'top':
                path += ' ' + endCX + ',' + (endT - cpDist)
                    + ' ' + endCX + ',' + endT;
                break;
            case 'bottom':
                path += ' ' + endCX + ',' + (endB + cpDist)
                    + ' ' + endCX + ',' + endB;
                break;
            case 'left':
            default:
                path += ' ' + (endL - cpDist) + ',' + (endT + end.height / 2)
                    + ' ' + endL + ',' + (endT + end.height / 2);
                break;
            case 'right':
                path += ' ' + (endR + cpDist) + ',' + (endT + end.height / 2)
                    + ' ' + endR + ',' + (endT + end.height / 2);
                break;
        }

        return path;
    }

    /**
     * TODO
     */
    private updateConnectors(meatball: any) {
        this.data.connectors.forEach((value, i, arr) => {
            if (value.from === meatball.id || value.to === meatball.id) {
                //@ts-ignore
                document.getElementById('connector-' + i).setAttribute('d', this.connectorPath(value));
            }
        });
    }

    /**
     * Drag Handlers
     */
    private dragstarted(element, d) {
        //@ts-ignore
        d3.select(element).raise().classed('active', true);
    }

    /**
     * TODO
     */
    private dragged(element, d) {
        //@ts-ignore
        let project = d3.select(element);
        let newX = d.x += d3.event.dx;
        let newY = d.y += d3.event.dy;
        let id = project.attr('data-id');
        let projectData = this.data.projects[id];
        let meatball = this.meatballs[id];
        
        // if the data is not defined for the meatball during drag, it will use the system integration data
        if (d.isSharedServices) {
            projectData = this.data.systemIntegration[id];
            meatball = this.siMeatballs[id];
        }

        // Prevents meatball from being dragged outside of chart
        // or violating the hard/soft links to source/dependant modules
        //let validationResults = validateNewCoordinates
        let results = this.checkBoundaries(d, newX, newY);
        //@ts-ignore
        newX = results.x;
        //@ts-ignore
        newY = results.y;

        // let tempHeight = data.phases[data.phases.length - 1].y;
        if (newY > (this.height - d.height)) { newY = this.height - d.height; }

        project.attr('x', newX).attr('y', newY);

        // Update project color
        let color = this.updateProjectColor(project, newX, newY);

        // Determine new year and month
        let newYear = Math.floor(newX / this.colWidth);
        let newMonth = Math.round((newX % this.colWidth) / this.colSliceWidth);

        if (newMonth === 12) {
            newYear++;
            newMonth = 0;
        }

        // Update data objects
        projectData.year = newYear + 1;
        meatball.year = newYear;
        projectData.month = meatball.month = newMonth;
        projectData.x = meatball.x = newX;
        projectData.y = newY / this.height;
        meatball.y = newY;
        meatball.color = color;
        this.updateConnectors(meatball);
        this.updateYearTotals();

        // validate and update project colors
        this.updateProjectColors();
    }

    /**
     * TODO
     */
    private checkBoundaries(item, newX, newY) {
        let results = {
            x: newX,
            y: newY
        };

        // validate chart boundaries not exceeded
        if (newX < 0) { results.x = 0; }
        if (newX > (this.width - item.d)) { results.x = this.width - item.d; }
        if (newY < 0) { results.y = 0; }

        return results;
    }

    /**
     * TODO
     */
    private validateDependencies(id, item, newX, newY) {
        let isValid = true;

        // validate not dragged earlier than a source meatball
        let paths = _.filter(this.data.connectors, connector => {
            return connector.to === id;
        });

        _.forEach(paths, (path) => {
            let sourceMeatball = this.findMeatballById(path.from);
            let xLimit = sourceMeatball.x;

            if (path.type === 'hard') {
                xLimit = sourceMeatball.x + (sourceMeatball.r * 2);
            }

            if (newX < (xLimit - this.dragValidationBuffer)) {
                isValid = false;
            }
        });

        // validate not dragged after dependent meatballs
        paths = _.filter(this.data.connectors, connector => {
            return connector.from === id;
        });

        _.forEach(paths, (path) => {
            let dependentMeatball = this.findMeatballById(path.to);
            let xLimit = dependentMeatball.x;

            if (path.type === 'hard') {
                xLimit = xLimit - item.d;
            }

            if (newX > (xLimit + this.dragValidationBuffer)) {
                isValid = false;
            }
        });

        return isValid;
    }

    /**
     * TODO
     */
    private updateProjectColor(project, newX, newY) {
        //@ts-ignore
        let id = project.attr('data-id');
        let d = project.data()[0];
        let projectData = this.data.projects[id];

        // if the data is not defined for the meatball during drag, it will use the system integration data
        if (d.isSharedServices) {
            projectData = this.data.systemIntegration[id];
        }

        // Update phase
        let valid = this.validateDependencies(projectData.id, d, newX, newY);
        let color = this.invalidProjectColor;
        let textColor = this.invalidProjectTextColor;

        if (valid) {
            textColor = 'black';
            let y = d.y + d.r;
            if (!!d.height) {
                y = d.y + d.height / 2;
            }
            let phase;
            let i;
            for (i = this.data.phases.length - 1; i > -1; i--) {
                phase = this.data.phases[i];
                if (y > phase.y) {
                    color = phase.color;
                    projectData.phase = i;
                    break;
                }
            }
        }

        // updates color when half the data is in different swim lane
        project.select('circle.outer').style('fill', color);
        project.select('circle.inner').style('fill', color);
        project.select('rect.rect').style('fill', color);
        project.select('rect.inner').style('fill', color);
        project.select('text.label').style('fill', textColor);

        return color;
    }

    /**
     * TODO
     */
    private dragended(element, d) {
        //@ts-ignore
        d3.select(element).classed('active', false);
    }

    /**
     * TODO
     */
    private generateMeatballData(project, index) {
        let isBuy = project.buy && (project.showBuy || !project.build),
            buildBuyData = isBuy ? project.buy : project.build,
            r = (this.colSliceWidth * buildBuyData.duration) / 2,
            phase = this.data.phases[project.phase];

        let projectYear = project.year - 1;
        
        return {
            id: project.id,
            programId: this.programId,
            moduleId: project.moduleId,
            x: (this.colWidth * projectYear) + (project.month * this.colSliceWidth),
            y: project.y ? this.height * project.y : phase.y + (this.dataUtils.getPhaseHeight(phase) / 2) - r,
            r: r,
            d: r * 2,
            color: this.data.phases[project.phase].color,
            title: project.title,
            value: buildBuyData.value,
            // value: (project.type === 'SI') ? project.baseValue : buildBuyData.value,
            year: projectYear,
            month: project.month,
            duration: buildBuyData.duration,
            phase: project.phase,
            baseValue: project.baseValue,
            valueSharesMap: this.initValueShareMap(project)
        };
    }

    /**
     * Will initialize the value share map for this project
     * Project level share will override global level shares
     */
    private initValueShareMap(project) {
        let valueSharesMap = new Map<string,number>();

        // set the defaults
        this.setValueShares(valueSharesMap, this.data.valueShares);

        // now set any overrides
        let isBuy = project.buy && (project.showBuy || !project.build),
            buildBuyData = isBuy ? project.buy : project.build;
        this.setValueShares(valueSharesMap, buildBuyData.valueShares);

        return valueSharesMap;
    }

    /**
     * TODO
     */
    private setValueShares(valueSharesMap, valueShares) {
        if (!!valueShares && valueShares.length > 0) {
            valueShares.forEach(valueShare => {
                valueSharesMap.set(valueShare.key, valueShare.share);
            });
        }
    }

    /**
     * TODO
     */
    private drawmeatballs(meatballData, isSystemIntegration = false) {
        try {
            let roadmap = this;
            let svgClassName = (!isSystemIntegration) ? 'project' : 'projectSI';
            
            // Add the meatballs (after the connectors, to place them in front) DONE
            let projects = this.svg.selectAll('svg.' + svgClassName)
                .data(meatballData);
            
            let projectGroup = projects.enter().append('svg')
                .attr('class', svgClassName)
                .attr('x', this.dataUtils.getX)
                .attr('y', this.dataUtils.getY)
                .attr('width', this.dataUtils.getD)
                .attr('height', this.dataUtils.getD)
                .attr('data-id', function (d, i) { return i; });

            // if (!isSystemIntegration) {
            //@ts-ignore
            projectGroup.call(d3.drag()
                .on('drag', function(d) {
                    roadmap.dragged(this, d);
                })
                .on('end', function (d) {
                    roadmap.dragended(this,d)
                }));
            // }

            // done
            projectGroup.append('circle')
                .attr('class', 'outer')
                .attr('cx', this.dataUtils.getR)
                .attr('cy', this.dataUtils.getR)
                .attr('r', this.dataUtils.getR)
                .style('fill', this.dataUtils.getColor);
            // done
            projectGroup.append('circle')
                .attr('class', 'inner')
                .attr('cx', this.dataUtils.getR)
                .attr('cy', this.dataUtils.getR)
                .attr('r', function (d: any) { return d.r * 0.9; })
                .style('fill', this.dataUtils.getColor);
            // done
            projectGroup.append('circle')
                .attr('class', function (d) {
                    let value = 'stroke';
                    return value;
                })
                .attr('cx', this.dataUtils.getR)
                .attr('cy', this.dataUtils.getR)
                .attr('r', this.dataUtils.getR);

            // Add text via each, so that the project group's width can be passed into the wrap function
            projectGroup.each(function (d, index, group) {
                let aGroup = d3.select(group[index]);

                aGroup.append('text')
                    .attr('x', roadmap.dataUtils.getR)
                    .attr('y', roadmap.dataUtils.getR)
                    .attr('text-anchor', 'middle')
                    .attr('data-id', (d: any) => d.id)
                    .attr('class', (d: any) => !!d.moduleId ? 'module-link' : '')
                    .text(roadmap.dataUtils.getTitle)
                    .call(roadmap.wrap, roadmap.dataUtils.getD(d), roadmap.dataUtils.getD(d), roadmap.dataUtils.getCurrentValueFormatted(d), roadmap.fontSize, d)
            });

            // validate the projects
            this.updateProjectColors();
        } catch (error) {
            console.error('Error drawing the meatballs', error);
        }
    }

    /**
     * This function will generate additional System integration data if it is missing from the original data.
     * 
     * NOTE: this is no longer required with the new specification provided on sep 18
     */
    private generateAdditionalSIData() {
        if (this.data.systemIntegration.length < this.data.years.length) {
            let yOrdinate = this.data.systemIntegration[0].y;
            for (let i = (this.data.systemIntegration.length - 1); i < (this.data.years.length - 1); i++) {
                let newData = {
                    id: i + 101,
                    title: `Modules Integration`,
                    description: '',
                    phase: 3,
                    year: i + 2,
                    month: 0,
                    y: yOrdinate,
                    build: { value: 0, duration: 12 },
                    baseValue: 3000,
                    type: 'SI'
                }
                this.data.systemIntegration.push(newData);
            }
        }
    }

    // Bringing it all together to draw the chart
    public drawChart() {
        let roadmap = this;

        // initialize the chart
        this.chartDiv = document.getElementById('meatballChart');
        this.svg = d3.select('#meatballChart');

        //@ts-ignore
        this.width = this.chartDiv.clientWidth;
        //@ts-ignore
        this.height = this.chartDiv.clientHeight;
        this.colWidth = this.width / this.data.years.length;
        this.halfColWidth = this.colWidth / 2;
        this.colSliceWidth = this.colWidth / 12; // We're slicing columns into 12 months

        // generateAdditionalSIData();

        // Create the meatball data
        this.meatballs = this.data.projects.map((project, index) => this.generateMeatballData(project, index));
        this.siMeatballs = this.data.systemIntegration.map((project, index) => this.generateMeatballData(project, index));

        // Ensure our chart is empty
        this.svg.selectAll('*').remove();
        // svg.attr('viewBox',`0 0 ${height} ${width}`)
        // .attr('preserveAspectRatio', 'xMidYMid meet')

        // Add connector marker svg
        this.addMarker();

        this.calculateYearTotals();

        // Calculate the phase positions
        let lastY = 0;
        for (let i = 0, iLength = this.data.phases.length; i < iLength; i++) {
            let phase = this.data.phases[i];
            phase.y = lastY;
            lastY += this.dataUtils.getPhaseHeight(phase);
        }

        // Add the phase backgrounds DONE
        this.svg.selectAll('rect.phase')
            .data(this.data.phases)
            .enter().append('rect')
            .attr('class', 'phase-bg')
            .attr('x', 0)
            .attr('y', this.dataUtils.getY)
            .attr('width', this.width)
            .attr('height', this.dataUtils.getPhaseHeight)
            .style('fill', this.dataUtils.getColor);

        // Add the phase background color caps DONE
        this.svg.selectAll('rect.phase')
            .data(this.data.phases)
            .enter().append('rect')
            .attr('x', -10)
            .attr('y', this.dataUtils.getY)
            .attr('width', 10)
            .attr('height', this.dataUtils.getPhaseHeight)
            .style('fill', this.dataUtils.getColor);

        // Add the phase labels DONE
        this.svg.selectAll('text.phase-label')
            .data(this.data.phases)
            .enter().append('text')
            .attr('class', 'phase-label')
            .attr('x', function (d) { return - roadmap.dataUtils.getY(d) - (roadmap.dataUtils.getPhaseHeight(d) / 2); })
            .attr('y', '-20')
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'middle')
            .attr('font-size', this.fontSize)
            .text(this.dataUtils.getTitle);

        // Add the year lines DONE
        this.svg.append('g')
            .selectAll('line')
            .data(this.data.years)
            .enter().append('line')
            .attr('x1', function (d, i) { return i * roadmap.colWidth; })
            .attr('y1', 0)
            .attr('x2', function (d, i) { return i * roadmap.colWidth; })
            .attr('y2', this.height)
            .attr('class', 'year-line');


        switch (this.data.chartType) {
            case 'meatball':
                this.drawmeatballs(this.meatballs);
                break;
            case 'rectangle':
            default: {
                this.drawRectangles(this.meatballs);
                this.drawRectangles(this.siMeatballs, true);
            }
        }

        // Add the connectors
        this.connectors = this.svg.selectAll('path.connector')
            .data(this.data.connectors)
            .enter().append('path')
            .attr('id', function (d, i) { return 'connector-' + i; })
            .attr('class', function (d: any) { return 'connector ' + (d.type === 'soft' ? 'soft' : 'hard'); })
            .attr('marker-end', '')
            .attr('d', this.connectorPath).lower();
        
        // Add the year labels
        let yearLabels = this.svg.selectAll('svg.year')
            .data(this.data.years)
            .enter().append('svg')
            .attr('class', 'year')
            .attr('x', function (d, i) { return i * roadmap.colWidth + roadmap.halfColWidth; })
            .attr('y', this.height - this.labelOffset)

        yearLabels.append('text')
            .attr('class', 'year-label')
            .attr('x', '0')
            .attr('y', '20')
            .attr('text-anchor', 'middle')
            .attr('font-size', this.fontSize)
            .text(this.dataUtils.getTitle);

        yearLabels.append('text')
            .attr('id', function (d, i) { return 'year-value-' + i; })
            .attr('class', 'year-value')
            .attr('x', '0')
            .attr('y', '20')
            .attr('dy', '1.25em')
            .attr('text-anchor', 'middle')
            .attr('font-size', this.fontSize)
            .text(this.dataUtils.getCurrentValueFormatted);
    }

    /**
     * TODO
     */
    private calculateSIValue(value) {
        console.log(value);
        return this.modulePercent * value / 100;
    }

    /**
     * TODO
     */
    private drawRectangles(meatballData, isSystemIntegration = false) {
        try {
            let roadmap = this;
            let svgClassName = (!isSystemIntegration) ? 'project' : 'projectSI';
            let selector = 'svg.' + svgClassName;

            // Add the meatballs (after the connectors, to place them in front) DONE
            let projects = this.svg.selectAll(selector).data(meatballData);

            // done
            let projectGroup = projects.enter().append('svg')
                .attr('class', svgClassName)
                .attr('x', this.dataUtils.getX)
                .attr('y', this.dataUtils.getY)
                .attr('width', this.dataUtils.getD)
                .attr('data-id', function (d, i) { return i; })
                .on("dblclick", (d) => {
                    if (!!this.props.editProjectDataCallback) {
                        this.props.editProjectDataCallback(d.id);
                    }
                });
            // if (isSystemIntegration) {
            //     projectGroup.attr('height', '100%')
            // } else {
            projectGroup.attr('height', (d: any) => d.d);
            // }

            // if (!isSystemIntegration) {
            //@ts-ignore
            projectGroup.call(d3.drag()
                .on('drag', function (d) {
                    roadmap.dragged(this, d);
                })
                .on('end', function (d) {
                    roadmap.dragended(this, d);
                }));

            
            let rect1 = projectGroup.append('rect')
                .attr('class', 'rect')
                .attr('rx', this.roundedCorners)
                .attr('ry', this.roundedCorners)
                .attr('width', (d: any) => d.r * 2)
                .style('fill', this.dataUtils.getColor);
            
            let rect2 = projectGroup.append('rect')
                .attr('class', 'inner')
                .attr('rx', this.roundedCorners)
                .attr('ry', this.roundedCorners)
                .attr('width', (d: any) => d.r * 2)
                .style('fill', this.dataUtils.getColor);
            
            let rect3 = projectGroup.append('rect')
                .attr('class', function (d) {
                    let value = 'stroke';
                    return value;
                })
                .attr('rx', this.roundedCorners)
                .attr('ry', this.roundedCorners)
                .attr('width', (d: any) => d.r * 2);

            this.updateRectangleHeights(rect1, isSystemIntegration);
            this.updateRectangleHeights(rect2, isSystemIntegration);
            this.updateRectangleHeights(rect3, isSystemIntegration);

            // Add text via each, so that the project group's width can be passed into the wrap function
            projectGroup.each(function (d, index, group) {
                let aGroup = d3.select(group[index]);
                let width = roadmap.dataUtils.getD(d);
                let height = d.height;

                aGroup.append('text')
                    .attr('class', 'label')
                    .attr('x', roadmap.dataUtils.getR)
                    .attr('y', (d: any) => d.height)
                    .attr('text-anchor', 'middle')
                    .attr('id', (d: any) => 'meatball-label-' + (d.id + 1))
                    .attr('class', (d: any) => !!d.moduleId ? 'module-link' : '')
                    .text(roadmap.dataUtils.getTitle)
                    .call(roadmap.wrap, width, height, roadmap.dataUtils.getCurrentValueFormatted(d), roadmap.fontSize, d);
            });

            // validate the projects
            this.updateProjectColors();
        } catch (error) {
            console.error('Error drawing the meatballs', error);
        }
    }

    /**
     * Validates and updates the project colors
     */
    private updateProjectColors() {
        ['project', 'projectSI'].forEach((svgClassName) => {
            let selector = 'svg.' + svgClassName;

            // validate and update the project colors
            this.svg.selectAll(selector).each((d, index, group) => {
                this.updateProjectColor(d3.select(group[index]), d.x, d.y);
            });
        });
    }

    /**
     * TODO
     */
    private updateRectangleHeights(selector, isSystemIntegration) {
        let roadmap = this;

        selector.attr('height', (d: any) => {
            let phase = roadmap.data.phases[d.phase];
            let phaseHeight = roadmap.dataUtils.getPhaseHeight(phase);
            let maxHeight = phaseHeight - phaseHeight / 2;
            d.height = this.scaleReactangleHeight(maxHeight, d.value);
            d.isSharedServices = isSystemIntegration;
            return d.height;
        });
    }

    /**
     * This function will set the height of the rectangle based upon the height of the swim lane
     * and value. It will determine the max and min value of the project and set the max height as the max value
     * @param maxHeight 
     * @param value 
     */
    private scaleReactangleHeight(maxHeight, value) {
        //@ts-ignore
        let maxValue = _.maxBy(this.data.projects, project => project.build.value).build.value;
        return Math.round(this.baseHeight + maxHeight * value / maxValue);
    }

    /**
     * TODO
     */
    public initChart(programId: number, data: any, valueShare = 'total') {
        this._updateChart(true, programId, data, valueShare);
    }

    /**
     * TODO
     */
    public destroyChart() {
        window.removeEventListener("resize", this.drawChart);
    }

   /**
    * TODO
    */
    public updateChart(programId: number, data: any, valueShare = 'total') {
        this._updateChart(false, programId, data, valueShare);
    }

    private _updateChart(initial = false, programId: number, data: any, valueShare: string) {
        this.programId = programId;
        this.data = data;
        this.valueShare = valueShare;

        this.drawChart();
        this.updateTotalBudget();

        if (initial) {
            window.addEventListener("resize", this.drawChart);
        }
    }
}
