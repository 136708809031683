import React from 'react';
import '../programPanels.css';
import Card from '../../cards/cards';
import * as _ from 'lodash';
import Store from '../../../store/Service';
import { RouteComponentProps } from 'react-router-dom';
import IRouteKey from '../../../interfaces/IRouteKey';

class ProgramPanel extends React.Component<RouteComponentProps, any> {
    constructor(props){
        super(props);
        let dashboardId = props.match.params.dashboardId;
        let programData = Store.appData.programsData[dashboardId];

        this.state = {
            dashboardId: dashboardId,
            dashboardTitle: programData.title,
            programDescription: programData.description,
            programs: _.sortBy(programData.programs, program => program.order)
        };
    }

    componentDidMount(){
        console.log("Entering Program Panel");
    }

    componentWillUnmount(){
        console.log("Leaving Program Panel");
    }
    render() {
        return (
            <div className='panelContainer'>
                <div className='panelHeader'>
                    <h1>{this.state.dashboardTitle}</h1>
                </div>
                <div className='panelDescription'>
                    {this.state.programDescription}
                </div>
                <div className='cardContainer'>
                    {
                        this.state.programs.map(program => {
                            
                            let routeKey:IRouteKey = {
                                dashboardId: this.state.dashboardId,
                                dashboardTitle: this.state.dashboardTitle,
                                programId: program.id,
                                programTitle: program.title
                            }
  
                            return (
                                    <Card key={program.id}
                                        routeKey={routeKey}
                                        title={program.title}
                                        svg={program.svg}
                                        icon={program.icon}
                                        width={program.width}
                                        cornerImg={program.cornerImg}
                                    />
                                );
                            }
                        )
                    }
                </div>
            </div>
        );
    }
}

export default ProgramPanel;
