import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import IRouteKey from '../../interfaces/IRouteKey';
import RouteFactory from '../../utils/RouteFactory';

import './cards.css';

interface ICards extends RouteComponentProps {
    routeKey: IRouteKey;
    /** @deprecated */
    title: string; 

    icon: string;
    cornerImg: string;
    svg?: any;
    width?: number;
    invisible?: boolean;
}

class Cards extends React.Component<ICards, any> {

    private handleClick = () => {
        let routes = RouteFactory.createRoutes(this.props.routeKey);
        this.props.history.push(routes.programLink());
    }

    render() {
        return (
            <div className={`${this.props.routeKey.programTitle ? "cards" : "card-placeholder"}${this.props.width ? " width-" + this.props.width : ""}${!this.props.icon ? " no-icon" : ""}${this.props.cornerImg ? "" : " no-corner-icon"}`}
                onClick={this.handleClick}>
                {this.props.cornerImg ? <img className='cornerImg' src={this.props.cornerImg} alt=''></img> : null }
                {this.props.icon && <img className={`cardIcon ${this.props.cornerImg ? "" : 'marginTop'}`} src={this.props.icon}  alt=''/> }
                <div className='cardDescription'>
                    {this.props.routeKey.programTitle}
                </div>
            </div>
        );
    }
}

export default withRouter(Cards);
